import { ref } from 'vue'

const siteLoading = ref(false)
const siteQueries = ref(0)
const useSiteLoading = () => {
  const addQuery = () => {
    siteQueries.value += 1
    siteLoading.value = true
  }
  const removeQuery = () => {
    siteQueries.value -= 1
    if (siteQueries.value === 0) {
      siteLoading.value = false
    }
  }

  return {
    siteLoading,
    siteQueries,
    addQuery,
    removeQuery,
  }
}

export default useSiteLoading
