<template>
  <transition name="fade">
    <div
      v-if="navOpen && device !== 'desktop'"
      class="overlay bg-primary/60 h-screen w-screen fixed left-0 top-0 z-30 backdrop-blur-md"></div>
  </transition>
  <header
    id="header-nav"
    ref="header"
    class="no-cursor header-nav bg-white"
    :class="`${navOpen ? 'nav-open' : ''} ${
      device === 'tablet' || device === 'mobile' ? 'mobile' : ''
    } ${sticky ? 'sticky-header' : ''}`">
    <nav ref="navWrapper">
      <div class="branding relative">
        <div class="logo-container">
          <a
            href="/"
            @click="close(false)">
            <transition
              name="fade"
              mode="out-in">
              <template v-if="device === 'desktop'">
                <!-- <DotLottieVue
                  ref="headerAnimation"
                  class="site-logo"
                  :autoplay="false"
                  :src="navAnimation" /> -->
                <img
                  v-if="site.site_logo?.url"
                  :src="site.site_logo?.url || ''"
                  alt="site-logo" />
              </template>
              <template v-else>
                <img
                  :src="site.site_logo_sticky?.url || ''"
                  alt="site-logo" />
              </template>
            </transition>
          </a>
        </div>
        <div
          class="mobile-nav"
          @click="toggle">
          <span class="line line-one" />
          <span class="line line-two" />
          <span class="line line-three" />
          <span class="line line-four" />
        </div>
      </div>
      <div class="nav-container-wrapper">
        <ul
          ref="nav"
          class="nav-container">
          <template v-if="introShown">
            <transition-group
              v-if="device === 'desktop'"
              appear
              name="fade">
              <li
                v-for="(item, index) in menu.menuItems.nodes"
                :key="item.id"
                class="nav-container-items"
                @click="item.cssClasses[0] === '' ? close(false) : null">
                <NavMenuItem
                  :key="item.id"
                  :data-ind="index"
                  :item="item"
                  :mobile="device === 'mobile' ? 'mobile-menu-show' : ''" />
              </li>
            </transition-group>
            <template v-else>
              <Vue3SlideUpDown
                v-model="navOpen"
                class="z-3 relative">
                <div class="inner-items-container">
                  <li
                    v-for="(item, index) in menu.menuItems.nodes"
                    :key="item.id"
                    class="nav-container-items-mobile"
                    @click="item.cssClasses[0] === '' ? close(false) : null">
                    <NavMenuItem
                      :key="item.id"
                      :data-ind="index"
                      :item="item"
                      :mobile="device === 'mobile' ? 'mobile-menu-show' : ''"
                      @click="toggle" />
                  </li>
                </div>
              </Vue3SlideUpDown>
            </template>
          </template>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script setup>
// import { DotLottieVue } from '@lottiefiles/dotlottie-vue'
// import navAnimation from '@/assets/lottie/logo_animated.json?url'
import NavMenuItem from './NavMenuItem.vue'
import { ref, watch, onMounted } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/composables/useUtils.js'
import useIntroShown from '@/composables/useIntroShown.js'
import useSmoothScroll from '@/composables/useSmoothScroll.js'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Vue3SlideUpDown } from 'vue3-slide-up-down'

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const site = ref(store.acfOptions())
const header = ref()
const sticky = ref()
const navOpen = ref()
const scrollPosition = ref(0)
const { device } = useUtils()
const { introShown } = useIntroShown()
const { smoothScroll } = useSmoothScroll()
// const headerAnimation = ref()

watch(scrollPosition, (newVal) => {
  scrollPosition.value = newVal
  if (newVal > 20) {
    sticky.value = true
  } else {
    sticky.value = false
  }
})

// watch(introShown, (newVal) => {
//   if (newVal) {
//     const dotLottie = headerAnimation.value.getDotLottieInstance()
//     dotLottie.play()
//     dotLottie.addEventListener('frame', ({ currentFrame }) => {
//       if (currentFrame > 60) {
//         dotLottie.pause()
//       }
//     })
//   }
// })

onMounted(() => {
  // if (introShown.value) {
  //   const dotLottie = headerAnimation.value.getDotLottieInstance()
  //   dotLottie.addEventListener('frame', ({ currentFrame }) => {
  //     if (currentFrame < 60) {
  //       dotLottie.play()
  //     } else {
  //       dotLottie.pause()
  //     }
  //   })
  // }
  show_hide_header()
  stickyOnScroll()
  smoothScroll.on('scroll', (e) => {
    handleScroll(e)
  })
})

const handleScroll = (e) => {
  scrollPosition.value = e.animatedScroll // Adjust the threshold as needed
}

const toggle = () => {
  navOpen.value = !navOpen.value
}

watch(navOpen, (newVal) => {
  if (!newVal) {
    smoothScroll.start()
  } else {
    smoothScroll.stop()
  }
})

const close = (data) => {
  if (data !== undefined) navOpen.value = data
  else navOpen.value = !navOpen.value
}

const show_hide_header = () => {
  if (!header.value) {
    setTimeout(() => {
      show_hide_header()
    }, 100)
  } else {
    const site_header = header.value

    const show_hide_header = gsap
      .from(site_header, {
        yPercent: -100,
        duration: 0.3,
        ease: 'sine.out',
      })
      .progress(1)

    ScrollTrigger.create({
      start: 'top top-=' + 100,
      endTrigger: 'body',
      end: 'bottom top',
      markers: false,
      onUpdate: (self) => {
        if (self.direction === -1) show_hide_header.play()
        else show_hide_header.reverse()
      },
    })
  }
}

const stickyOnScroll = () => {
  if (
    scrollPosition.value > 0 &&
    device.value !== 'tablet' &&
    device.value !== 'mobile'
  ) {
    header.value.classList.add('sticky')
    sticky.value = true
  } else {
    header.value.classList.remove('sticky')
    sticky.value = false
  }
}
</script>

<style lang="scss">
#header-nav.mobile {
  @apply fixed left-0 right-0 top-0;
  nav {
    @apply relative bg-white;
  }
  .nav-container-wrapper {
    .nav-container {
      .nav-container-items {
        @apply bg-transparent opacity-0;
      }
    }
  }
}

#header-nav {
  @apply fixed left-0 right-0 top-0 shadow-none transition-all duration-300 ease-in-out py-3 lg:py-4 z-40;

  nav {
    @apply container;
  }

  .logo-container img {
    @apply w-48 transition-all lg:w-full;
  }
  .nav-container-wrapper {
    @apply w-full transition-all lg:h-auto;
  }
}

#header-nav.sticky-header {
  @apply bg-white lg:shadow-md;
}

#header-nav.nav-open {
  @apply max-h-[100vh] lg:max-h-[110px];

  .line-one {
    @apply translate-x-[1px] translate-y-[11px] rotate-45 transform;
  }
  .line-three {
    @apply translate-x-[2px] translate-y-[5px] -rotate-45 transform;
  }
  .line-four {
    @apply translate-x-[-1px] translate-y-[3px] rotate-45 transform;
  }
  .line-two {
    @apply -translate-x-[0px] translate-y-[7px] -rotate-45 transform;
  }

  .nav-container-wrapper {
    @apply pointer-events-auto top-0 opacity-100;
  }
}
.header-nav {
  @apply top-0 mb-0 mt-0 flex items-stretch justify-center bg-transparent lg:overflow-visible lg:pb-2 lg:pt-6;

  nav {
    @apply flex flex-col items-stretch pt-0 transition-all duration-300 ease-in-out w-full lg:flex-row lg:items-center lg:justify-between;

    .nav-container {
      @apply my-0 flex flex-col justify-start pl-4 lg:flex-row lg:justify-end lg:gap-6 lg:px-0 xl:gap-10 xl:px-0;
      li {
        @apply w-full text-left lg:w-auto lg:border-t-0;

        &:first-child {
          @apply border-t-0;
        }
      }
    }
  }
  .branding {
    @apply flex w-full items-center justify-between text-center shadow-none md:text-left lg:max-h-[60px] lg:w-[242px] lg:flex-[1_0_auto] lg:p-0 xl:p-0;
    .logo-container {
      @apply flex h-full items-center lg:inline-block xl:max-h-full;
      img {
        @apply h-full max-h-[60px] w-[90%] lg:w-auto;
      }
    }
    .mobile-nav {
      @apply float-right flex flex-col lg:hidden;
      .line {
        @apply mb-0.5 transition-all duration-[0.5s] ease-in-out;
      }
      .line:before {
        @apply block h-[1px] w-[30px] bg-primary content-[''];
      }
    }
  }

  .nav-container {
    @apply m-0 flex h-full w-full items-start justify-start text-left transition duration-500 ease-in-out;
    .nav-container-items {
      @apply w-full items-center text-center lg:w-auto hidden lg:flex;
      div.menu-items {
        @apply header-2 flex h-full items-start justify-start text-left nav;
        :hover {
          @apply text-secondary;
        }
        a {
          &.router-link-active {
            @apply text-secondary;
          }
        }
      }
    }
    .slide-up-down__container {
      .inner-items-container {
        @apply py-6 xl:py-0;
        .nav-container-items-mobile {
          div.menu-items {
            @apply header-2 flex h-full items-start justify-start text-left nav py-4;
            :hover {
              @apply text-secondary;
            }
          }
        }
      }
    }
  }
}
</style>
