<template>
  <div
    ref="intro"
    class="intro">
    <div class="intro-container">
      <div class="intro-animation">
        <div ref="animation"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import lottie from 'lottie-web'
import introJson from '@/assets/lottie/intro.json?url'
import introJsonMobile from '@/assets/lottie/intro-mobile.json?url'
import useIntroShown from '@/composables/useIntroShown.js'
import { ref, onMounted } from 'vue'
import { gsap } from 'gsap'
import useUtils from '@/composables/useUtils.js'

const { setIntroComplete } = useIntroShown()
const animation = ref()
const { device } = useUtils()

onMounted(() => {
  document.documentElement.classList.add('no-overflow')
  if (animation.value) {
    const lottieAnim = lottie.loadAnimation({
      container: animation.value, // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: true,
      path: device.value === 'desktop' ? introJson : introJsonMobile, // the path to the animation json
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    })

    lottieAnim.setSpeed(1.5)

    lottieAnim.addEventListener('complete', () => {
      gsap.to(animation.value, {
        autoAlpha: 0,
        duration: 0.5,
        delay: 0,
        onComplete: () => {
          setIntroComplete()
          document.documentElement.classList.remove('no-overflow')
        },
      })
    })
  }
})
</script>

<style lang="scss">
.intro {
  @apply fixed left-0 top-0 z-[51] h-full w-full bg-white;
  .intro-container {
    @apply relative left-0 flex h-[100vh] w-[100vw] items-center justify-center bg-cover bg-no-repeat;
    .intro-animation {
      @apply m-0 h-screen w-screen;
      div {
        @apply w-full h-full;
      }
    }
  }
}
</style>
