import gsap from "gsap"
import emitter from '../scripts/emitter'
/**
 * This composable is used to control the scroll behavior during page transitions.
 * Make the variables global so they can be accessed from the router.
 */

window.transitionElement = null

export default function useTransitionAndScroll() {
/**
 * Call this before the leave phase of the transition
 */
function transitionOut(el, done) {
  window.transitionElement = el
  gsap.to(el, {
    duration: 0.5,
    opacity: 0,
    onComplete: done
  })
}

/**
 * Call this in the enter phase of the transition
 */
function transitionIn() {
    setTimeout(() => {
      emitter.emit('scrollPing')
    }, 500)
}


function callBack(resolve) {
  gsap.to(window.transitionElement, {
    duration: 0.5,
    opacity: 1,
    onComplete: resolve
  })
}

  return {
    transitionOut,
    transitionIn,
    callBack
  }

}

