import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client/core'
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist'
import useSiteLoading from './composables/useSiteLoading'

const { addQuery, removeQuery } = useSiteLoading()

const siteURL = `${window.__VUE_WORDPRESS__.state.site.url}/graphql`
// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: siteURL,
})

// Cache implementation
const cache = new InMemoryCache()

// loading middleware
const middleware = new ApolloLink((operation, forward) => {
  addQuery()
  return forward(operation)
})

const afterware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    removeQuery()
    return response
  })
})

// Persist cache
const persist = async () => {
  if (typeof window.__IS_DEV__ === 'undefined') {
    await persistCache({
      cache,
      storage: new LocalStorageWrapper(window.sessionStorage),
    })
  }
}
persist()

// Create the apollo client
export const apolloClient = new ApolloClient({
  link: ApolloLink.from([middleware, afterware, httpLink]),
  cache,
})
