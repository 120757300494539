import { ref, onBeforeMount } from 'vue'

const introShown = ref(false)

export default function useIntroShown() {
  const setIntroComplete = () => {
    introShown.value = true
    sessionStorage.setItem('introShown', true)
  }

  onBeforeMount(() => (introShown.value = sessionStorage.getItem('introShown')))

  return {
    introShown,
    setIntroComplete,
  }
}
