import Lenis from 'lenis'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { useResizeObserver } from '@vueuse/core'

gsap.registerPlugin(ScrollTrigger)

const locoScroll = new Lenis({
  duration: 1.2,
  easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
  orientation: 'vertical', // vertical, horizontal
  gestureOrientation: 'vertical', // vertical, horizontal, both
  smoothWheel: true,
  wheelMultiplier: 1,
  smoothTouch: false,
  touchMultiplier: 2,
  infinite: false,
})

locoScroll.on('scroll', ScrollTrigger.update)

gsap.ticker.add((time) => {
  locoScroll.raf(time * 1000)
})

gsap.ticker.lagSmoothing(0)

useResizeObserver(document.body, () => {
  ScrollTrigger.update()
  ScrollTrigger.refresh()
})

function raf(time) {
  locoScroll.raf(time)
  requestAnimationFrame(raf)
}

requestAnimationFrame(raf)

export default locoScroll
