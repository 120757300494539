<template>
  <div
    id="website-wrapper"
    @click="handleClicks">
    <transition
      name="fade"
      mode="out-in">
      <SiteIntro v-if="!introShown" />
    </transition>
    <div
      ref="navMenuRef"
      class="z-10">
      <nav-menu />
    </div>
    <div
      id="website-content"
      ref="wrapper">
      <div class="web-inner-wrapper">
        <router-view v-slot="{ Component }">
          <transition
            :css="false"
            mode="out-in"
            @leave="(el, done) => transitionOut(wrapper, done)"
            @after-enter="transitionIn">
            <component
              :is="Component"
              ref="mainElement"
              :key="$route.path" />
          </transition>
        </router-view>
      </div>
      <transition name="fade">
        <app-footer v-show="siteFooter" />
      </transition>
    </div>
  </div>
</template>

<script setup>
import NavMenu from './components/template-parts/NavMenu/NavMenu.vue'
import AppFooter from './components/template-parts/Footer.vue'
import SiteIntro from '@/components/utility/SiteIntro.vue'
import { ref } from 'vue'
import { useStore } from '@/stores/main'
import { useRouter } from 'vue-router'
import useIntroShown from './composables/useIntroShown.js'
import useSmoothScroll from './composables/useSmoothScroll.js'
import useTransitionAndScroll from './composables/useTransitionAndScroll.js'

const router = useRouter()
const store = useStore()
const site = ref(store.site)
const wrapper = ref(null)
const navMenuRef = ref(null)
const { introShown } = useIntroShown()
const mainElement = ref(null)
const siteFooter = ref(true)
const { locoScroll } = useSmoothScroll()
const { transitionOut, transitionIn } = useTransitionAndScroll()
const getLinkEl = (el) => {
  while (el.parentNode) {
    if (el.tagName === 'A') return el
    el = el.parentNode
  }
}

const handleClicks = (e) => {
  const a = getLinkEl(e.target)
  if (a && a.href.includes(site.value.url)) {
    const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = e
    // don't handle if has class 'no-router'
    if (a.className.includes('no-router')) return
    // don't handle with control keys
    if (metaKey || altKey || ctrlKey || shiftKey) return
    // don't handle when preventDefault called
    if (defaultPrevented) return
    // don't handle right clicks
    if (button !== undefined && button !== 0) return
    // don't handle if `target="_blank"`
    if (a.target && a.target.includes('_blank')) return
    // don't handle same page links
    const currentURL = new URL(a.href, window.location.href)
    if (currentURL && currentURL.pathname === window.location.pathname) {
      // if same page link has same hash prevent default reload
      if (currentURL.hash === '') return

      const currtURL = currentURL
      const windowLocation = window.location
      if (currtURL.hash === windowLocation.hash) e.preventDefault()

      const el = document.querySelector(currtURL.hash)
      if (el) {
        if (currtURL.hash) locoScroll.scrollTo(currtURL.hash)
      }
    }
    // Prevent default and push to vue-router
    e.preventDefault()
    const path = a.href.replace(site.value.url, '')
    router.push(path)
  }
}
</script>

<style lang="scss">
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Vue transition classes
-------------------------------------------- */

// #website-wrapper {
//   @apply pt-24 lg:pt-28;
// }

.web-inner-wrapper {
  @apply min-h-screen;
}

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.4s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity 0.4s ease-in;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.3, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

#website-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

// Lenis Styles START
html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

// Lenis Styles END
</style>
