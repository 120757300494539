import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import locoScroll from '../assets/js/lenisScroll'
import useTransitionAndScroll from '../composables/useTransitionAndScroll'
import emitter from '../scripts/emitter'

const { site } = window.__VUE_WORDPRESS__.state
const { callBack } = useTransitionAndScroll()


const scrollToSection = (to, resolve) => {
  locoScroll.resize()
  if(to.hash) {
    locoScroll.scrollTo(to.hash, {
      duration: 0.5,
      onComplete: () => {
        callBack(resolve)
      }
    })
  } else {
    locoScroll.scrollTo(to, {
      duration: 0.5,
      onComplete: () => {
        callBack(resolve)
      }
    })
  }
}


export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      emitter.once('scrollPing', () => {
        if (to.hash) {
          scrollToSection(to, resolve)
        } else {
          if (savedPosition) {
            const scrollValue =
              savedPosition.top === 0 ? 'top' : savedPosition.top
              scrollToSection(scrollValue, resolve)
          } else {
            scrollToSection('top', resolve)
          }
        }
      })
    })
  },
})

router.afterEach((to) => {
  // set page title on route change
  window.document.title =
    `${to.meta.title} | ${site.name}` || `${site.name} | ${site.description}`
})
