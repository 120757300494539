import { defineStore } from 'pinia'

const { state } = window.__VUE_WORDPRESS__
const finalState = Object.assign(state, { cardIndex: 0 })

export const useStore = defineStore('main', {
  state: () => finalState,
  getters: {
    menu:
      (state) =>
      ({ name }) => {
        return state.menus.find((x) => x.slug === name)
      },
    acfOptions: (state) => () => {
      return state.acf_options
    },
  },
  actions: {
    setLoading(loading) {
      if (loading === false) {
        setTimeout(() => {
          this.site.loading = loading
        }, 500)
      } else {
        this.site.loading = loading
      }
    },
    setIntro(introShown) {
      this.site.introShown = introShown
    },
    setDocTitle(title) {
      this.site.docTitle = title
    },
    updateDocTitle({ parts = [], sep = ' – ' }) {
      parts[0]
        ? this.setDocTitle(unescape(parts.join(sep)))
        : this.setDocTitle(unescape(parts[1]))
      document.title = this.site.docTitle
    },
  },
})
